<script lang="ts">
	// props
	export let alt = "";
	export let classes = "";
	export let draggable = "false";
	export let src = "";
	export let height: string | null = null;
	export let loading: string | null = "lazy";
	export let mimeType: string | null = null;
	export let width: string | null = null;
	export let style: string | null = null;
	export let title: string | null = null;
</script>

<template lang="pug">
	img(
		class!="{ 'w-full h-auto select-none ' + classes }",
		alt!="{ alt }",
		data-mimeType!="{ mimeType }",
		draggable!="{ draggable }",
		height!="{ height }",
		loading!="{ loading }",
		src!="{ src }",
		style!="{ style }",
		title!="{ title }",
		width!="{ width }"
	)
</template>
