<script lang="ts">
	export let type = "";
	export let srcset = "";
	export let media: string | null = null;
</script>

<template lang="pug">
	source(
		media!="{ media }",
		srcset!="{ srcset }",
		type!="{ type }"
	)
</template>
