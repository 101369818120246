// import types
import type { Image, ImageSet, Breakpoint } from "$types/types";

export function buildMediaString(min?: number | null, max?: number | null) {
	// build the media string that will go in each <source>
	// e.g. only screen and (min-width:0px) and (max-width:419.99px)
	if (min || max) {
		const minScrubbed = min != null && min != undefined ? min.toString() : "";
		const minRule = minScrubbed ? ` and (min-width:${minScrubbed}px)` : "";
		const maxScrubbed = max ? max.toString() : "";
		const maxRule = maxScrubbed ? ` and (max-width:${maxScrubbed}px)` : "";
		const template = `only screen${minRule ? minRule : ""}${
			maxRule ? maxRule : ""
		}`;
		return template;
	} else return "";
}
export function createImageSet(imageSet: Image[], media: string) {
	// the picture stack component takes ImageSets
	// they look like this:
	// { images: imageXS, media: 'only screen and ...'}
	if (imageSet) {
		const obj: ImageSet = {
			images: imageSet,
		};
		if (media) obj.media = media;
		return obj;
	}
}
export function createImageStack(breakpoints: Breakpoint[]) {
	// a stack of imageSets based on the breakpoint settings
	const sets: ImageSet[] = [];
	breakpoints.forEach((bp) => {
		const images: Image[] = bp[0] ? bp[0] : [];
		const min: number | null = typeof bp[1] == "number" ? bp[1] : null;
		const max: number | null = typeof bp[2] == "number" ? bp[2] : null;
		const media = buildMediaString(min, max);
		const result = createImageSet(images, media);
		if (result) sets.push(result);
	});
	return sets;
}

export function selectImageByType(array: Image[], type: string) {
	return array.filter((image) => image.mimeType == `image/${type}`)[0];
}

export function getImageWidth(imageSet: ImageSet) {
	// will return an image width
	const images = imageSet.images;
	const image = images.length > 0 ? images[0] : null;
	const width = image && image.width ? image.width : 0;
	const widthNum = typeof width === "string" ? parseInt(width) : width;
	return widthNum ? widthNum : 0;
}

export function getImageSetSizes(imageSets: ImageSet[]) {
	// this will return an array of image width values 1 from each set
	const imageSizes: number[] = imageSets.map((imageSet) =>
		getImageWidth(imageSet),
	);
	return imageSizes;
}

export function getFallBackImageSize(imageSizes: number[], fallback: number) {
	// this will find the closest value to fallback * 2
	const target = fallback * 2;
	const closest = imageSizes.reduce(function (prev, curr) {
		return Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev;
	});
	return closest;
}

export function getImageSetBySize(imageWidth: number, imageSets: ImageSet[]) {
	// returns imageSet that contains an image with the given imageWidth
	const matches = imageSets.filter(
		(imageSet) => getImageWidth(imageSet) == imageWidth,
	);
	return matches[0];
}

export function getFallbackSet(imageSets: ImageSet[], fallback: number) {
	// parses image sets to find best fallback
	// best == width is closet to fallback prop value * 2
	const imageSizes: number[] = getImageSetSizes(imageSets);
	const fallBackImageSize: number = getFallBackImageSize(imageSizes, fallback);
	const fallbackSet = getImageSetBySize(fallBackImageSize, imageSets);
	return fallbackSet;
}
